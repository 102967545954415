import {Page} from "../js/page";
import {GvfService} from "../../gvf/js/gvf-service";
import {UtilsService} from "../js/utils-service";

export class LoginPage extends Page{
    constructor(scope,$root){
        super(scope,$root);
    }
    
    ready(){
        if(this.$root.data("popup-message")){
            swal(
                {
                    type: "info",
                    title: "",
                    text: this.$root.data("popup-message")
                }
            );
        }

        this.$loginForm = this.findMember("login-form");
        this.$recoverForm = this.findMember("recover-form");
        this.findMember("recover-link").click(
            (ev)=>{
                ev.preventDefault();
                this.$recoverForm.find("[name=email]").val(this.$loginForm.find("[name=email]").val());
                this.$loginForm.slideUp(
                    ()=>{
                        this.$recoverForm.slideDown();
                    }
                );
            }
        );

        this.$loginForm.submit(
            (ev)=>{
                if(!this.$loginForm.data("restrictionsOk")){
                    ev.preventDefault();
                    this.$loginForm.checkRestrictions().then(
                        (isOk) => {
                            if(isOk){
                                this.$loginForm.data("restrictionsOk",true);
                                this.$loginForm.submit();
                            }
                        }
                    );
                }
            }
        );

        this.$recoverForm.submit(
            (ev)=>{
                ev.preventDefault();
                const $button = this.$recoverForm.find(this.scope+"__recover-button");
                $button.prop("disabled",true);
                GvfService.endpoint(
                    "/cp/login/reset-pass-request",
                    {data:this.$recoverForm.getValues()}
                ).then(UtilsService.alertEndpointResponse)
                .catch(UtilsService.alertEndpointResponse)
                .finally(
                    ()=>{
                        $button.prop("disabled",false);
                    }
                );
            }
        );

        this.findMember("recover-cancel-button").click(
            (ev)=>{
                ev.preventDefault();
                this.$recoverForm.slideUp(
                    ()=>{
                        this.$loginForm.slideDown();
                    }
                );
            }
        );
    }
}

