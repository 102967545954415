import 'babel-polyfill';
import {BodyComponent} from "../components/body/body-component";
import {Form} from "../components/form/form";
import {FormField} from "../components/form-field/form-field";
import {FormFieldInput} from "../components/form-field-input/form-field-input";
import {LoginPage} from "../login-page/login-page";
import {GvfUiService} from "../../gvf/js/gvf-ui-service";
import {GoogleAuthenticatorPage} from "../google-authenticator-page/google-authenticator-page";

GvfUiService.registerComponent(".js-body",BodyComponent);
GvfUiService.registerComponent(".js-form",Form);
GvfUiService.registerComponent(".js-form-field",FormField);
GvfUiService.registerComponent(".js-form-field-input",FormFieldInput);
GvfUiService.registerComponent(".js-login-page",LoginPage);
GvfUiService.registerComponent(".js-google-authenticator-page",GoogleAuthenticatorPage);
