import {Page} from "../js/page";

export class GoogleAuthenticatorPage extends Page{
    constructor(scope,$root){
        super(scope,$root);
    }
    
    ready(){
        this.$googleAuthenticatorQr = this.findMember("google-authenticator-qr");
        this.$googleAuthenticatorQr.hide();
        this.$googleAuthenticatorLink = this.findMember("google-authenticator-link");
        this.$googleAuthenticatorLink.click(
            (ev)=>{
                ev.preventDefault();
                this.$googleAuthenticatorQr.toggle();
            }
        );

        this.$googleAuthenticatorForm = this.findMember("form");
        this.$googleAuthenticatorForm.submit(
            (ev)=>{
                if(!this.$googleAuthenticatorForm.data("restrictionsOk")){
                    ev.preventDefault();
                    this.$googleAuthenticatorForm.checkRestrictions().then(
                        (isOk) => {
                            if(isOk){
                                this.$googleAuthenticatorForm.data("restrictionsOk",true);
                                this.$googleAuthenticatorForm.submit();
                            }
                        }
                    );
                }
            }
        );
    }
}

