import {Component} from "../../../gvf/js/component";
import {GvfService} from "../../../gvf/js/gvf-service";
import {UtilsService} from "../../js/utils-service";
import {ObjectService} from "../../js/object-service";
import {FcmService} from "../../js/fcm-service";
import {GvfUiService} from "../../../gvf/js/gvf-ui-service";

export class BodyComponent extends Component{
    constructor(scope,$root){
        super(scope,$root);
        this.zIndex = 1050;
    }

    ready(){
        if(this.$root.data("popup-message")){
            swal(
                {
                    type: "info",
                    title: "",
                    text: this.$root.data("popup-message")
                }
            );
        }

        this.findMember("logout").click(
            (ev) => {
                ev.preventDefault();
                $(ev.currentTarget).addClass("is-loading");
                GvfService.endpoint(
                    "/cp/cp/logout",
                    {}
                ).then(
                    (response) => {
                        window.location = response.url;
                    }
                ).catch(
                    (response)=>{
                        UtilsService.alertEndpointResponse(response);
                    }
                ).finally(
                    ()=>{
                        $(ev.currentTarget).removeClass("is-loading");
                    }
                );
            }
        );

        //modal links functionality
        GvfUiService.ready("a[href*='#modal-']",($element)=>{
            $element.unbind().click(
                (ev)=>{
                    ev.preventDefault();
                    ev.stopPropagation();
                    const $link = $(ev.currentTarget);
                    const parts = $link.attr("href").split("#modal-");
                    const params = GvfService.parseUrlParams(parts[0].split("?")[1]);
                    const model = parts[1];
                    const objectService = new ObjectService(model.replace(/-/g,"_"));
                    objectService.openDetailModal({
                        objectId: params.id,
                        editMode: params.edit==1,
                        lang: params.lang,
                        saveCallback: ()=>{
                            $link.trigger("object:updated",{model});
                        },
                        deleteCallback: ()=>{
                            $link.trigger("object:deleted",{model});
                        }
                    });
                }
            );
        });

        GvfUiService.ready("a[href^='#modallist-']",($element)=>{
            $element.parent().click( //bug in textfit library
                (ev)=>{
                    ev.preventDefault();
                    ev.stopPropagation();
                    const parts = $element.attr("href").split("#modallist-");
                    UtilsService.createFormModal({
                        formEndpoint: "/cp/stat-report-detail/get-model-ids-detail",
                        formEndpointParams: {ids_value:parts[1]},
                        size: "lg",
                        title: GvfService.lang("Ver detalle")
                    });
                }
            );
        });

        //tooltips functionality
        GvfUiService.ready("[data-toggle=\"tooltip\"]",($element)=>{
            $element.tooltip({boundary:"viewport"});
        });

        //Nested modals scroll fix
        $(document).on(
            "hide.bs.modal",
            ".modal",
            ()=>{
                this.zIndex--;
                if($(".modal:visible").length>0){
                    $(document.body).addClass("modal-open");
                }
                $(".modal-backdrop").css("z-index",this.zIndex-1);
            }
        );
        $(document).on(
            "show.bs.modal",
            ".modal",
            (ev)=>{
                this.zIndex++;
                $(ev.target).css("z-index",this.zIndex);
                this.$root.removeClass("minimized-modals");
            }
        );
        $(document).on(
            "shown.bs.modal",
            ".modal",
            (ev)=>{
                $(".modal-backdrop").css("z-index",this.zIndex-1);
                const $minimize = $(ev.target).find(".minimize");
                $minimize.unbind().click(
                    ()=>{
                        this.$root.toggleClass("minimized-modals modal-open");
                        $minimize.blur();
                    }
                );
            }
        );

        if(this.$root.data("firebase-vapid") && this.$root.data("firebase-config")){
            FcmService.init(this.$root.data("firebase-vapid"),this.$root.data("firebase-config"));
        }
    }
}
