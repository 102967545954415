import {Component} from "../../gvf/js/component";
import {GvfService} from "../../gvf/js/gvf-service";

export class Page extends Component{
    showLoading(){
        const $page = $("body");
        $page.addClass("is-starting-loading");
        GvfService.delay(500,"loading").then(
            () => {
                if($page.hasClass("is-starting-loading")){
                    $page.addClass("is-loading");
                }
            }
        );
    }

    hideLoading(){
        $("body").removeClass("is-starting-loading is-loading");
    }

    ready(){
        //specific code
    }
}