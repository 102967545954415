import {GvfService} from "../../gvf/js/gvf-service";
import {GvfUiService} from "../../gvf/js/gvf-ui-service";

class UtilsService{
    /**
     * Creates an alert based on an endpoint response
     * @param {*} response
     */
    alertEndpointResponse(response){
        if(typeof response == "string"){
            response = {error:true,message:response};
        }

        let isError = true;
        let message = "Error";
        if($.isPlainObject(response)){
            if(response.hasOwnProperty("statusText")){ //ajax catched error
                isError = (response.status!=200);
                message = response.status>0?response.statusText:"Offline";
                if(response.hasOwnProperty("responseJSON")){
                    response = response.responseJSON;
                }else{
                    isError = true;
                }
            }else{
                isError = (response.hasOwnProperty("error") && response.error);
            }
            if(response.hasOwnProperty("message") && typeof response.message == "string"){
                message = response.message;
            }
        }

        let type = isError?"error":"success";

        if(message.length<300){
            swal(
                {
                    type: type,
                    title: "",
                    text: message
                }
            );
        }else{
            const $modal = $("<div class=\"modal\" tabindex=\"-1\"><div class=\"modal-dialog modal-dialog-scrollable\" role=\"document\"><div class=\"modal-content\"><div class=\"modal-body\">"+message.replace(/\n/,"<br />")+"</div></div></div></div>");
            $("body").append($modal);
            $modal.modal("show");
        }
    }

    /**
     * Creates form modal and returns Promise
     * @param {string} containerEndpoint
     * @param {string} title Title to show
     * @param {string} size Size of modal: xl, lg, etc
     * @param {boolean} showModalButtons
     * @returns {Promise<jQuery>}
     */
    createFormModalContainer({containerEndpoint,title,size,showModalButtons}){
        return GvfService.endpoint(
            containerEndpoint??"/cp/cp/render-modal-form",
            {title,size,showModalButtons}
        ).then(
            (resp)=>{
                const $modal = $(resp.html);
                $("body").append($modal);
                GvfUiService.init($modal);
                return $modal;
            }
        ).catch(this.alertEndpointResponse);
    }

    /**
     * Creates form modal
     * @param {string} formEndpoint Endpoint url to save form
     * @param {object?} formEndpointParams Additional parameters
     * @param {string} saveEndpoint Endpoint url to save form
     * @param {string?} containerEndpoint Endpoint url to get container
     * @param {string?} title Title to show
     * @param {string?} size Size of modal: xl, lg, etc
     * @param {string?} confirmLabel Confirm button label
     * @param {string?} cancelLabel Cancel button label
     * @param {function?} readyCallback
     * @param {function?} confirmCallback
     * @param {function?} errorCallback
     */
    createFormModal({formEndpoint,formEndpointParams,saveEndpoint,containerEndpoint,title,size,confirmLabel,cancelLabel,readyCallback,confirmCallback,errorCallback}){
        this.createFormModalContainer({containerEndpoint,title,size,showModalButtons:!!saveEndpoint}).then(
            ($modal)=>{
                GvfService.endpoint(
                    formEndpoint,
                    {params:formEndpointParams??{}}
                ).then(
                    (resp)=>{
                        const $modalForm = $(resp.html);
                        $modal.component().setForm($modalForm);
                        GvfUiService.init($modalForm);
                        $modal.modal({show:true,backdrop: 'static'});
                        if(readyCallback){
                            readyCallback($modal);
                        }
                        if(saveEndpoint){
                            $modalForm.submit(
                                (ev)=>{
                                    ev.preventDefault();
                                    const data = $modalForm.getValues();
                                    $modalForm.checkRestrictions().then(
                                        (isOk)=>{
                                            if(isOk){
                                                $modal.addClass("is-loading");
                                                GvfService.endpoint(
                                                    saveEndpoint,
                                                    {formData:data}
                                                ).then(
                                                    (resp)=>{
                                                        $modal.modal("hide");
                                                        $modal.on("hidden.bs.modal",()=>{
                                                            $modal.remove();
                                                        });

                                                        if(confirmCallback){
                                                            confirmCallback(resp);
                                                        }else{
                                                            if(resp.message){
                                                                this.alertEndpointResponse(resp);
                                                            }

                                                            if(resp.url){
                                                                window.location = resp.url;
                                                            }
                                                        }
                                                    }
                                                ).catch(this.alertEndpointResponse).finally(
                                                    ()=>{
                                                        $modal.removeClass("is-loading");
                                                    }
                                                );
                                            }
                                        }
                                    );
                                }
                            );
                        }
                    }
                ).catch(
                    (err)=>{
                        this.alertEndpointResponse(err);
                        if(errorCallback){
                            errorCallback();
                        }
                    }
                );
            }
        );
    }

    modelToCamelCase(model){
        return model.charAt(0).toLowerCase()+model.slice(1);
    }

    camelCaseToDash(str){
        return str.replace(/([a-zA-Z])(?=[A-Z])/g,"$1-").toLowerCase();
    }
}

const s_UtilsService = new UtilsService();
export {s_UtilsService as UtilsService};
