import {UtilsService} from "../js/utils-service";
import {GvfUiService} from "../../gvf/js/gvf-ui-service";
import {GvfService} from "../../gvf/js/gvf-service";

export class ObjectService{
    constructor(model){
        this.model = model;
    }

    getListItemId($item){
        if($item.data("id")){
            return $item.data("id");
        }else{
            const href = $item.find("a").eq(0).attr("href");
            if(href.indexOf("#")==0){
                return href.substring(1);
            }else{
                const params = GvfService.parseUrlParams(href);
                return params.id;
            }
        }
    }

    /**
     * Creates object modal and returns Promise
     * @param {string} objectId
     * @param {bool} editMode
     * @param {string?} lang
     * @param {string?} size
     * @returns {Promise<jQuery>}
     */
    createDetailModal({objectId,editMode,lang,size}){
        return GvfService.endpoint(
            "/cp/"+UtilsService.camelCaseToDash(this.model)+"-detail/render-detail-modal",
            {model: this.model,objectId: objectId,editMode: editMode,lang: lang,size: size}
        ).then(
            function(resp){
                const $modal = $(resp.html);
                $("body").append($modal);
                return $modal;
            }
        ).catch(UtilsService.alertEndpointResponse);
    }

    /**
     * Gets object detail HTML and returns Promise
     * @param {string} objectId
     * @param {boolean} editMode
     * @param {string} lang
     * @param {Object} context
     * @param {Object} data
     * @returns {Promise<string>}
     */
    getDetailHtml({objectId,editMode,lang,context,data}){
        return GvfService.endpoint(
            "/cp/"+UtilsService.camelCaseToDash(this.model)+"-detail/render-detail-content",
            {model: this.model,objectId: objectId,editMode: editMode,lang: lang,context: context,data: data}
        ).then(
            (resp)=>{
                return resp.html;
            }
        );
    }

    /**
     * Opens modal
     * @param {string} objectId
     * @param {boolean?} editMode
     * @param {string?} lang
     * @param {saveCallback?} saveCallback
     * @param {Object?} data
     * @param {string?} size
     * @param {deleteCallback?} deleteCallback
     * @param {readyCallback?} readyCallback
     *
     * @callback saveCallback
     * @param {string} id
     * @param {any} response
     *
     * @callback readyCallback
     * @param {jQuery} id
     *
     * @callback deleteCallback
     * @param {string} id
     *
     * @returns {Promise<jQuery>}
     */
    openDetailModal({objectId,editMode,lang,saveCallback,data,deleteCallback,readyCallback,size}){
        return this.createDetailModal({objectId,editMode,lang,size}).then(
            ($modal)=>{
                $modal.modal({show:true, backdrop: 'static'});
                $modal.addClass("is-loading");
                this.getDetailHtml({objectId: objectId,editMode: editMode,lang: lang,context: "modal",data: data}).then(
                    (html)=>{
                        $modal.removeClass("is-loading");
                        const $modalContent = $modal.find(".js-detail-modal__body");
                        const $detailPage = $(html);
                        let fCallback = (id,response)=>{
                            $modal.modal("hide");
                            if(saveCallback){
                                saveCallback(id,response);
                            }
                        };
                        $detailPage.data("saveCallback",fCallback);
                        fCallback = (id)=>{
                            $modal.modal("hide");
                            if(deleteCallback){
                                deleteCallback(id);
                            }
                        };
                        $detailPage.data("deleteCallback",fCallback);
                        $detailPage.data("modalData",data);
                        $modalContent.html($detailPage);
                        GvfUiService.init($modalContent);
                        if(readyCallback){
                            readyCallback($detailPage);
                        }
                    }
                ).catch((err)=>{
                    UtilsService.alertEndpointResponse(err);
                    //must wait until animations are finished
                    setTimeout(()=>{
                        $modal.removeClass("is-loading").modal("hide");
                    },1000);
                });
                return $modal;
            }
        ).catch(UtilsService.alertEndpointResponse);
    }

    /**
     * Deletes object
     * @param {string[]|string} ids
     * @param {string?} lang
     * @param {string?} controller
     * @param {boolean?} redirectUser
     * @returns {Promise}
     */
    deleteObject({ids,lang,controller,redirectUser}){
        if(!$.isArray(ids)){
            ids = [ids];
        }

        return new Promise(
            (resolve,reject)=>{
                if(ids.length>0){
                    swal({
                        title: GvfService.lang("¿Estás seguro?"),
                        text: ids.length>1?(GvfService.lang("Se eliminarán estos elementos de forma permanente")):(GvfService.lang("Se eliminará este elemento de forma permanente")),
                        type: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#fc4b6c",
                        confirmButtonText: GvfService.lang("Sí, eliminar"),
                        cancelButtonText: GvfService.lang("Cancelar"),
                        closeOnConfirm: !redirectUser,
                        closeOnCancel: true
                    },(isConfirm)=>{
                        if(isConfirm){
                            GvfService.endpoint(
                                (controller?controller:"/cp/object-detail")+"/delete-object",
                                {modelName: this.model,objectId: ids,lang: lang}
                            ).then(
                                (resp)=>{
                                    if(redirectUser){
                                        if(resp.reload){
                                            window.location.reload();
                                        }else{
                                            window.location = resp.url;
                                        }
                                    }else{
                                        resolve();
                                    }
                                }
                            ).catch(UtilsService.alertEndpointResponse);
                        }
                    });
                }
            }
        );
    }

    /**
     * Creates a modal for select item and returns a Promise with it
     * @param model
     * @return {Promise<jQuery>}
     */
    createListModal(model){
        return GvfService.endpoint(
            "/cp/object-list/render-list-modal",
            {model: model}
        ).then(
            function(resp){
                const $modal = $(resp.html);
                $("body").append($modal);
                return $modal;
            }
        ).catch(UtilsService.alertEndpointResponse);
    }

    /**
     * Selects a model's item and returns a promise with selected id
     * @param {string?} endpoint
     * @param {object?} search
     * @param {object?} fieldData
     * @param {contentReadyCallback?} contentReadyCallback
     * @return Promise<string>
     *
     * @callback contentReadyCallback
     * @param {jQuery} $modal
     */
    selectModelItem({endpoint,search,fieldData,contentReadyCallback}){
        if(!endpoint){
            endpoint = UtilsService.camelCaseToDash(this.model)+"-list";
        }

        return this.createListModal(this.model).then(
            ($modal)=>{
                $modal.modal("show");
                $modal.addClass("js-form-field-model-selector__modal is-loading");
                $modal.data("fieldData",fieldData);

                return new Promise(
                    (resolve)=>{
                        const selectItem = (id)=>{
                            $modal.modal("hide");
                            resolve(id);
                        };

                        GvfService.endpoint(
                            "/cp/"+endpoint+"/render-list-content",
                            {
                                model: this.model,
                                search: search,
                                page: 0,
                                blockName: null,
                                fieldData: fieldData,
                                context: "model-selector"
                            }
                        ).then(
                            (resp)=>{
                                $modal.removeClass("is-loading");
                                const $modalBody = $modal.find(".js-list-modal__body");
                                $modalBody.html(resp.html);
                                $modal.data("addValue",selectItem);
                                $modal.data("setValue",selectItem);
                                $modalBody.find(".js-search-form").submit(
                                    (ev)=>{
                                        ev.preventDefault();
                                        $modal.modal("hide");
                                        this.selectModelItem({
                                            endpoint,
                                            search: $(ev.currentTarget).getValues(),
                                            fieldData,
                                            contentReadyCallback
                                        }).then(
                                            (id)=>{
                                                selectItem(id);
                                            }
                                        );
                                    }
                                );
                                $modalBody.find("a[href^=\"#\"]").click(
                                    (ev)=>{
                                        ev.preventDefault();
                                        $modal.modal("hide");
                                        selectItem($(ev.currentTarget).attr("href").substring(1));
                                    }
                                );
                                GvfUiService.init($modalBody);
                                if(contentReadyCallback){
                                    contentReadyCallback($modal);
                                }
                            }
                        ).catch(
                            function(resp){
                                $modal.removeClass("is-loading");
                            }
                        );
                    }
                );
            }
        );
    }
}
