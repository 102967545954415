import {GvfService} from "../../gvf/js/gvf-service";
import * as firebase from "firebase/app";
import { getMessaging, getToken,onMessage } from "firebase/messaging";

class FcmService{
    constructor(){

    }

    sendTokenToServer(currentToken){
        if(!this.isTokenSentToServer()){
            GvfService.endpoint("/cp/cp-device/register-token",{token: currentToken}).then(
                () => {
                    this.setTokenSentToServer(true);
                }
            );
        }
    }

    isTokenSentToServer(){
        return window.localStorage.getItem("sentToServer")==="1";
    }

    setTokenSentToServer(sent){
        window.localStorage.setItem("sentToServer",sent?"1":"0");
    }

    requestPermission(vapidKey){
        Notification.requestPermission().then(() => {
            getToken(this.messaging,{vapidKey:vapidKey}).then(
                (refreshedToken) => {
                    this.setTokenSentToServer(false);
                    this.sendTokenToServer(refreshedToken);
                }
            ).catch(
                ()=>{

                }
            );
        });
    }

    showNotification(payload){
        if(payload.data && payload.notification){
            let notification = new Notification(payload.notification.title?payload.notification.title:"",payload.notification);
        }
    }

    init(vapidKey,firebaseConfig){
        const app = firebase.initializeApp(firebaseConfig);

        try{
            this.messaging = getMessaging(app);
            onMessage(
                this.messaging,
                (payload)=>{
                    // Let's check if the browser supports notifications
                    if(!("Notification" in window)){
                        alert("This browser does not support desktop notification");
                    }else if(Notification.permission==="granted"){
                        this.showNotification(payload);
                    }else if(Notification.permission!=="denied"){
                        Notification.requestPermission().then((permission)=>{
                            // If the user accepts, let's create a notification
                            if(permission==="granted"){
                                this.showNotification(payload);
                            }
                        });
                    }
                }
            );
            this.requestPermission(vapidKey);
        }catch(e){
            console.log(e);
        }
    }
}

const s_FcmService = new FcmService();
export {s_FcmService as FcmService};
